import * as React from "react";
import { useState, useEffect } from "react"

import * as styles from "./ProjectId.module.scss";
import SingleProjectDescription from "./SingleProjectDescription";
import ContactForm from "@components/ContactForm";
import DataService from "@utils/DataService";

import cardFacilityKPI from "@images/project_id/card_facility_kpi.png";
import cardMagykRedesign from "@images/project_id/card_magyk_redesign.png";

const otherProjects = [
  {
    title: "Facility KPI",
    department: "Analytics",
    image: cardFacilityKPI
  },
  {
    title: "Magyk Redesign",

    department: "Website",
    image: cardMagykRedesign
  }
];

const SingleProjectComponent = ({ projectId }) => {

  const [project, setProject] = useState({})

  
  useEffect(() => {
    DataService.getCMSData(`project/${projectId}`).then(data => {
      setProject(data);
    });
  }, []);

  return (
    <div className={`${styles.project} container`}>

      <SingleProjectDescription {...project} />

      <section className={styles.more}>
        <h2>More of Our Projects</h2>
        <div className={styles.projects}>
          {otherProjects.map(project => (
            <div
              className={styles.project}
              style={{ backgroundImage: `url(${project.image})` }}
            >
              <h2>{project.title}</h2>
              <p>{project.department}</p>
            </div>
          ))}
        </div>
      </section>

      <ContactForm />

    </div>
  );
};

export default SingleProjectComponent;
