// extracted by mini-css-extract-plugin
export const project = "ProjectId-module--project--2uD-I";
export const top_card = "ProjectId-module--top_card--34JYD";
export const card_text = "ProjectId-module--card_text--23F0i";
export const card_img = "ProjectId-module--card_img--fMja3";
export const project_info = "ProjectId-module--project_info--2jC3s";
export const info_type = "ProjectId-module--info_type--3jhDN";
export const description = "ProjectId-module--description--5hK0I";
export const img = "ProjectId-module--img--2pOgp";
export const top_img = "ProjectId-module--top_img--33YUv";
export const challenge = "ProjectId-module--challenge--3N6NC";
export const step_title = "ProjectId-module--step_title--1k6yZ";
export const steps = "ProjectId-module--steps--3vL57";
export const solution = "ProjectId-module--solution--EG4nL";
export const more = "ProjectId-module--more--2U6eQ";
export const projects = "ProjectId-module--projects--yxUEX";
export const bouncy = "ProjectId-module--bouncy--1xJbT";
export const pop = "ProjectId-module--pop--2--Ee";