import * as React from "react";
import * as styles from "../ProjectId.module.scss";

const Step = ({ title, text, image, order }) => {

  return (
    <>
      <div className={styles.step_text}>
        <h3 className={styles.step_title}>
          {order}. {title}
        </h3>
        <p>{text}</p>
      </div>
      <img src={image} className={styles.img} alt={title} />
    </>
  );
};

const SingleProjectDescription = ({
  challenge,
  content,
  image,
  industry,
  introduction,
  platforms,
  solution,
  step1,
  step2,
  step3,
  step4,
  title,
  _type
}) => {
  function createMarkup(content) {
    return {
      __html: content
    };
  }

  const duration = "1 month (HARDCODED)";

  const info = { industry, Type: _type, platforms, duration };
  const steps = [step1, step2, step3, step4];

  return (
    <>
      <div className={styles.top_card}>
        <div>
          <h1>{title}</h1>
          <p
            className={styles.card_text}
            dangerouslySetInnerHTML={createMarkup(introduction)}
          ></p>
        </div>
        <img className={styles.card_img} src={image} alt="Single project top" />
      </div>

      <div className={styles.project_info}>
        {Object.keys(info).map(infoData => (
          <div>
            <h4 className={styles.info_type}>{infoData}</h4>
            <h4>{info[infoData]}</h4>
          </div>
        ))}
      </div>

      <p
        className={styles.description}
        dangerouslySetInnerHTML={createMarkup(content)}
      ></p>
      <img
        className={`${styles.img} ${styles.top_img}`}
        src={image}
        alt="Project Descritpion"
      />
      <img
        className={`${styles.img} ${styles.top_img}`}
        src={image}
        alt="Project Descritpion 2"
      />

      <article className={styles.challenge}>
        <div>
          <h2>Challenge</h2>
          <p className={styles.description}>{challenge}</p>
        </div>
        <img
          className={`${styles.img} ${styles.top_img}`}
          src={image}
          alt="Project Description bottom"
        />
      </article>

      <article className={styles.steps}>
        {steps.map((step, i) => (
          <Step key={i} {...step} />
        ))}
      </article>

      <article className={styles.solution}>
        <h2>Solution</h2>
        <p>{solution}</p>
        <img className={styles.img} src={image} alt="Project Solution" />
        <img className={styles.img} src={image} alt="Project Solution 2" />
        <p>{solution}</p>
      </article>
    </>
  );
};

export default SingleProjectDescription;
