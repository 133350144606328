import * as React from "react";
import Layout from "@components/layout";
import SEO from "@components/seo";
import SingleProjectComponent from "@components/Projects/ProjectId"

const SingleProjectPage = () => (
  <Layout>
    <SEO title="Single Project" />
    <h1 style={{display: "none"}}>Single Project</h1>
    <SingleProjectComponent />  
    <p>
    </p>
  </Layout>
);

export default SingleProjectPage;
